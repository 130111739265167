import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import images
// import americanEx from '../../images/payments/american-ex.png';
// import discover from '../../images/payments/discover.png';
// import masterCard from '../../images/payments/master-card.png';
// import paypal from '../../images/payments/paypal.png';
// import visa from '../../images/payments/visa.png';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {


    return (
      <React.Fragment>

      


     <footer className="footer" style={{backgroundColor:"#581C73"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <Link className="logo-footer" to="/">iKompass<span className="text-primary">.</span></Link>
                        <p className="mt-4">We are an education organization specializing in a niche set of courses and skills. Our primary area of focus is Data Science, Machine Learning, Artificial Intelligence, Project Management, Cyber Security and Serverless technologies.</p>
                        <p className="mt-4">PMI®, PMP®, PgMP®, PMBOK®, PMI-ACP®, and PMI Registered Education Provider logo are registered marks of Project Management Institute, Inc.</p>
                        <ul className="list-unstyled social-icon social mb-0 mt-4">
                            <li className="list-inline-item"><Link to="https://www.facebook.com/ikompass" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                            <li className="list-inline-item"><Link to="https://www.linkedin.com/company/438759" className="rounded mr-1"><i className="mdi mdi-linkedin" title="Linkedin"></i></Link></li>
                            <li className="list-inline-item"><Link to="https://twitter.com/ikompass1" className="rounded"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                        </ul>
                    </div>
                    
                    <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Company</h4>
                        <ul className="list-unstyled footer-list mt-4">                            
                            <li><Link onClick={()=>this.props.toggle('showcourseslist')} to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Courses</Link></li>                            
                            <li><Link onClick={()=>this.props.toggle('showpricing')} to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Pricing</Link></li>
                            <li><Link onClick={()=>this.props.toggle('showvideo')} to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Demo</Link></li>
                            <li><Link onClick={()=>this.props.toggle('showfaqs')} to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> FAQs</Link></li>
                            <li><Link onClick={()=>this.props.toggle('showcontact')} to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Contact</Link></li>
                            <li><Link onClick={()=>this.props.toggle('showschedule')} to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Schedule</Link></li>
                        </ul>
                    </div>
                    
                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Terms</h4>
                        <ul className="list-unstyled footer-list mt-4">
                            <li><Link onClick={()=>this.props.toggle('showcoursedeliverypolicy')} to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Course delivery policy</Link></li>                            
                            <li><Link onClick={()=>this.props.toggle('showtermsofservice')} to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Terms of Services</Link></li>                            
                            <li><Link onClick={()=>this.props.toggle('showprivacypolicy')} to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Privacy Policy</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Address</h4>
                        <div className="row">
                                <div className="col-lg-12">
                                    <p className="text-muted">iKompass, 58, Battalion Drive, Basking Ridge, NJ, 07920 USA</p>
                                    <p>+1 856 242 7198 </p>

                                </div>
                        </div>
                    </div>

                    {/*
                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Newsletter</h4>
                        <p className="mt-4">Sign up and receive the latest tips via email.</p>
                        <form>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="foot-subscribe form-group position-relative">
                                        <label>Write your email <span className="text-danger">*</span></label>
                                        <i className="mdi mdi-email ml-3 icons"></i>
                                        <input type="email" name="email" id="emailsubscribe" className="form-control pl-5 rounded" placeholder="Your email : " required />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <input type="submit" id="submitsubscribe" name="send" className="btn btn-primary w-100" value="Subscribe" />
                                </div>
                            </div>
                        </form>
                    </div>
                */}
                </div>
            </div>
        </footer>
        <hr />
        <footer className="footer footer-bar">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <div className="text-sm-left">
                            <p className="mb-0">©  {new Date().getFullYear()}  iKompass.</p>
                        </div>
                    </div>
                    {/*
                    <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <ul className="list-unstyled payment-cards text-sm-right mb-0">
                            <li className="list-inline-item mr-1"><Link to="#"><img src={americanEx} title="American Express" alt="" /></Link></li>
                            <li className="list-inline-item mr-1"><Link to="#"><img src={discover} title="Discover" alt="" /></Link></li>
                            <li className="list-inline-item mr-1"><Link to="#"><img src={masterCard} title="Master Card" alt="" /></Link></li>
                            <li className="list-inline-item mr-1"><Link to="#"><img src={paypal} title="Paypal" alt="" /></Link></li>
                            <li className="list-inline-item"><Link to="#"><img src={visa} title="Visa" alt="" /></Link></li>
                        </ul>
                    </div>
                */}

                </div>
            </div>
        </footer>
        
      </React.Fragment>
    );
  }
}

export default Footer;
